<template>
  <CContainer class="d-flex flex-column align-items-center min-vh-100">
    <img src="/img/404.png" class="float-left display-3 mr-4 w-50" alt="404" />
    <h4 class="pt-3">{{ $t("Oops! You're lost") }}</h4>
    <p class="text-muted">{{ $t('The page you are looking for was not found') }}</p>
  </CContainer>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
